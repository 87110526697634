import { loader } from 'graphql.macro'

export default async function voidInvoice ({ dataLoaders, variables }) {
  const schema = loader('./voidInvoice.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      id: variables.id
    }
  })
  return response.voidInvoice
}
