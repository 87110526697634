import dayjs from './dayjs'

export const EmailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
export const PasswordValidator = /^.{6,}$/
export const PrimaryPhoneValidator = (phone) => {
  const startsWith0 = phone.charAt(0) === '0'
  const startsWith1 = phone.charAt(0) === '1'
  const has12Chars = phone.length === 12
  return has12Chars && !startsWith0 && !startsWith1
}

/*
 * Validators that are useful for react-final-form. Include any of these validators in your <Field /> validate prop,
 * or use multiple with "composeValidators"
 */

export function composeValidators (...validators) {
  return value => validators.reduce((error, validator) => error || validator(value), undefined)
}

export function required (value) {
  if (value === null || value === undefined || value === '') {
    return 'Required'
  }

  return undefined
}

export function mustBeMoney (value) {
  if (value === null || value === undefined || value === '' || isNaN(value.toString().replace(',', ''))) {
    return 'Must be number'
  }

  const [, cents] = value.split('.')
  if (cents != null && cents.length >= 1 && cents.length !== 2) {
    return 'Cents can only be to the hundredth'
  }

  return undefined
}

export function requiredIf (requiredIf, message) {
  return (value) => {
    if (requiredIf() && (value === null || value === undefined || value === '')) {
      return `Required if ${message}`
    }

    return undefined
  }
}

export function maxStrLength (max) {
  return (value) => {
    if (value === null || value === undefined) {
      return
    }

    return value.length > max ? `Must be less than ${max} characters` : undefined
  }
}

export function validEmail (value) {
  if (EmailValidator.test(value)) return

  return 'Invalid email'
}

export function validMultipleEmails (value) {
  const emails = value.split(/[\s,]+/)
  const isErrorFree = emails.map(validEmail).every(v => v == null)
  if (!isErrorFree) return 'Invalid Emails'

  return undefined
}

export function validPassword (value) {
  if (PasswordValidator.test(value)) return

  return 'Password must be at least 6 characters'
}

export function validPhone (value) {
  if (value === undefined || value === null || value === '') return

  const startsWith0 = value.charAt(0) === '0'
  const startsWith1 = value.charAt(0) === '1'
  const has12Chars = value.length === 12
  if (has12Chars && !startsWith0 && !startsWith1) return

  return 'Invalid phone number'
}

export function mustMatch (matchValue, label) {
  return (value) => value === matchValue ? undefined : `Dot not match ${label}`
}

export function dateAfterDay (after, message) {
  return (value) => {
    if (value == null || value === '' || after == null || after === '') return

    const afterDate = dayjs(after, 'YYYY-MM-DD')
    if (dayjs(value, 'YYYY-MM-DD').isAfter(afterDate, 'day')) return

    return message ?? `Must be after ${afterDate.format('YYYY-MM-DD')}`
  }
}

export function dateOnOrAfterDay (on, message) {
  return (value) => {
    if (value == null || value === '' || on == null || on === '') return

    const onDate = dayjs(on, 'YYYY-MM-DD')
    const compareDate = dayjs(value, 'YYYY-MM-DD')
    if (compareDate.isSame(onDate, 'day')) return
    if (compareDate.isAfter(onDate, 'day')) return

    return message ?? `Must be after ${onDate.format('YYYY-MM-DD')}`
  }
}
