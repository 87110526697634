import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import { Form, Field } from 'react-final-form'
import cn from 'classnames'
import { maxStrLength } from '../../../utilities/commonValidators'
import { enumToTitleCase } from '../../../utilities/stringUtilities'
import { INVOICE_STATUSES, INVOICE_DATE_RANGES } from '../../../utilities/constants'
import Spinner from 'react-spinkit'
export default function InvoicesSearchForm ({
  onSubmit,
  onClear,
  isFetching = false,
  isWastepayEnabled,
  isWastepayApproved,
  initialValues = {},
  handleExport,
  isExporting
}) {
  function handleClear (form) {
    form.reset({})
    onClear()
  }

  return (
    <div className={styles.searchContainer}>
      <div className={cn(styles.panel, 'dis-panel')}>
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit} className={cn(styles.form, 'dis-panel-body')}>
              <div className={styles.inputs}>
                <label className={styles.label}>
                  Invoice Number
                  <Field name='invoiceNumber'>
                    {({ input }) => (
                      <input
                        {...input}
                        type='text'
                        className={cn(styles.input, 'form-control')}
                      />
                    )}
                  </Field>
                </label>
                <Field name='status'>
                  {({ input }) => (
                    <label className={styles.label}>
                      Status
                      <select
                        {...input}
                        type='select'
                        className='form-control'>
                        <option key='placeholder' value=''></option>
                        {Object.keys(INVOICE_STATUSES).map((opt) =>
                          (
                            <option
                              value={opt}
                              key={opt}>
                              {opt === 'CLOSED_IN_QBO' ? 'Closed in QBO' : enumToTitleCase(opt)}
                            </option>
                          )
                        )}
                      </select>
                    </label>
                  )}
                </Field>
                <label className={styles.label}>
                  Account Name
                  <Field name='accountName' validate={maxStrLength(255)}>
                    {({ input }) => (
                      <input
                        {...input}
                        type='text'
                        className={cn(styles.input, 'form-control')}
                        maxLength={255}
                      />
                    )}
                  </Field>
                </label>

                <Field name='createdDateRange'>
                  {({ input }) => (
                    <label className={styles.label}>
                      Created Date
                      <select
                        {...input}
                        type='select'
                        className='form-control'>
                        <option key='placeholder' value=''></option>
                        {Object.keys(INVOICE_DATE_RANGES).map((opt) =>
                          (
                            <option
                              value={opt}
                              key={opt}>
                              {enumToTitleCase(opt)}
                            </option>
                          )
                        )}
                      </select>
                    </label>
                  )}
                </Field>
              </div>
              <div className={styles.btns}>
                <button
                  type='button'
                  tabIndex={-1}
                  onClick={handleExport}
                  className='dis-btn dis-btn-sm dis-btn-primary-dk'>
                  {isExporting
                    ? (<Spinner name='circle' className='mx-auto' color='white' fadeIn='none' />)
                    : (
                      <>
                        <span className='pull-left'>Export</span>
                        <i className='material-icons pull-right space-left'>get_app</i>
                      </>)}
                </button>
                <div>
                  <button
                    type='submit'
                    className='dis-btn dis-btn-sm dis-btn-success'
                    disabled={isFetching}>
                    Search
                    <i className='material-icons dis-btn-icon'>search</i>
                  </button>
                  <button
                    type='button'
                    onClick={() => handleClear(form)}
                    className={cn(styles.clearBtn, 'dis-btn dis-btn-white dis-btn-sm')}
                    disabled={isFetching}>
                    Clear
                    <i className='material-icons dis-btn-icon'>clear</i>
                  </button>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
      {isWastepayEnabled && !isWastepayApproved && <NotWastepayApproved />}
    </div>
  )
}

InvoicesSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  isWastepayEnabled: PropTypes.bool.isRequired,
  isWastepayApproved: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    qboDocNumber: PropTypes.string,
    minTickets: PropTypes.number,
    clientName: PropTypes.string
  }),
  handleExport: PropTypes.func.isRequired,
  isExporting: PropTypes.bool.isRequired
}

function NotWastepayApproved () {
  return (
    <div className={styles.wpad}>
      <h6>NEW!</h6>
      <h2 className={styles.ad}>Credit Card Processing</h2>
      <p>With the new WastePay integration, charge a credit card from a ticket!</p>
      <a href='https://www.dispatcher.com/payments-demo' target='_blank' rel='noopener nofollow noreferrer'>Learn More</a>
    </div>
  )
}
