import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import classNames from 'classnames'
import { generatePath, Link } from 'react-router-dom'
import TicketStatusLabel from '../TicketStatusLabel'
import { googleMapsLink } from '../../utilities/locationUtilities'
import { stringToTime } from '../../utilities/stringUtilities'
import ReactTooltip from 'react-tooltip'
import { ROUTES } from '../../routes'

export default function DispatchTicketDisplay ({
  onUpdateTicketFlag,
  ticket,
  onAssignTicketToUser,
  onChangeSite,
  isDraggable = false,
  dragHandleProps = {}
}) {
  function handleTicketFlagChange () {
    onUpdateTicketFlag({ ticketId: ticket.id, flag: !ticket.flagged })
  }

  const showInvoiceIcon = ticket.billingStatus === 'PAID' || ticket.billingStatus === 'INVOICED'
  const ticketPath = generatePath(ROUTES.ticket, { id: ticket.id })
  const ticketClientPath = generatePath(ROUTES.client, { clientId: ticket.account.id })

  return (
    <div className='w-100 p-4 pr-0'>
      <ReactTooltip place='bottom' />
      <div className='-mx-6 flex flex-row'>
        <div className='w-24'>
          <Link to={ticketPath}>{ticket.customId}</Link>
        </div>
        <div className='flex flex-row w-full justify-between'>
          <Link
            className={styles.title}
            to={ticketPath}>
            <strong>
              {ticket.ticketType.name} - {ticket.resourceType.name} ({ticket.ticketType.shortCode}-{ticket.resourceType.shortCode})
            </strong>
          </Link>
          <div>
            {showInvoiceIcon &&
              <i
                data-tip={ticket.billingStatus === 'PAID' ? 'Paid' : 'Invoiced'}
                className={classNames('cursor-pointer relative -top-1 mr-4', {
                  'material-icons': ticket.billingStatus === 'PAID',
                  'material-icons-outlined': ticket.billingStatus === 'INVOICED'
                })}>paid</i>
            }
            <Link
              to={ticketPath}
              className='inline-block pull-right space-right font-size-1-25em line-height-1'>
              <TicketStatusLabel
                status={ticket.status}
                hasActiveException={ticket.hasActiveException || ticket.hasActiveException}
                exceptionMessage={ticket.exceptionMessage || ticket.exceptionMessage}
              />
            </Link>
          </div>
        </div>
      </div>

      {ticket.requestedStartTime && (
        <div className='ml-14 font-size-1em'>
          {`Requested: ${stringToTime(ticket.requestedStartTime)} - ${stringToTime(ticket.requestedEndTime)}`}
        </div>
      )}

      <div className='-mx-6'>
        <div className={classNames('w-full flex flex-row', {
          'pl-20': !isDraggable,
          '-mb-3': isDraggable
        })}>
          {ticket.driverId && isDraggable && (
            <div className='w-20' {...dragHandleProps}>
              <i className='material-icons'>drag_handle</i>
            </div>
          )}
          <Link to={ticketClientPath}>{ticket.account.name}</Link>
        </div>
        <a
          className='inline-block line-height-1 text-black ml-20'
          href={googleMapsLink(ticket.job)}
          target='_blank'
          rel='noopener noreferrer'>
          {ticket.job.addressline1} {ticket.job.addressline2}
          <br />
          {ticket.job.city || 'unknown'}, {ticket.job.state || 'unknown'} {ticket.job.zip || 'unknown'}
          {ticket.job.addressDetails && `(${ticket.job.addressDetails})`}
          &nbsp;
          <small className='text-primary link'>(View)</small>
        </a>
        <div className='btn-group pull-right no-padding space-right'>
          <button
            className='btn btn-xs btn-link'
            onClick={handleTicketFlagChange}>
            <i className={`material-icons ${ticket.flagged ? 'text-danger' : 'text-gray-med-dark'}`}>flag</i>
          </button>

          <div className='inline-block'>
            <button
              className='btn btn-xs btn-link'
              onClick={() => onAssignTicketToUser(ticket.id, true)}>
              <i className='material-icons text-gray-med-dark'>transfer_within_a_station</i>
            </button>
            <button
              className='btn btn-xs btn-link'
              onClick={() => onChangeSite(ticket.id)}>
              <i className={`material-icons ${ticket?.sites?.length > 0 ? 'text-primary' : 'text-gray-med-dark'}`}>location_on</i>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

DispatchTicketDisplay.propTypes = {
  onUpdateTicketFlag: PropTypes.func.isRequired,
  ticket: PropTypes.shape({
    id: PropTypes.string.isRequired,
    flagged: PropTypes.bool.isRequired,
    status: PropTypes.string,
    customId: PropTypes.string.isRequired,
    driverId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    hasActiveException: PropTypes.bool.isRequired,
    exceptionMessage: PropTypes.string,
    billingStatus: PropTypes.oneOf(['INVOICED', 'NOT_BILLABLE', 'PAID', 'PENDING']),
    account: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string
    }),
    job: PropTypes.shape({
      addressDetails: PropTypes.string,
      addressline1: PropTypes.string,
      addressline2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string
    }),
    resourceType: PropTypes.shape({
      name: PropTypes.string,
      shortCode: PropTypes.string
    }),
    ticketType: PropTypes.shape({
      name: PropTypes.string.isRequired,
      shortCode: PropTypes.string
    }),
    requestedStartTime: PropTypes.string,
    requestedEndTime: PropTypes.string,
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string
      })
    )
  }).isRequired,
  onAssignTicketToUser: PropTypes.func.isRequired,
  onChangeSite: PropTypes.func.isRequired,
  isDraggable: PropTypes.bool,
  dragHandleProps: PropTypes.any
}
