import React from 'react'
import Markdown from 'react-markdown'
import PropTypes from 'prop-types'

export default function MarkdownRenderizer ({ markdownText }) {
  return (
    <Markdown className='dis-mk-wrapper'>
      {markdownText}
    </Markdown>
  )
}

MarkdownRenderizer.propTypes = {
  markdownText: PropTypes.string.isRequired
}
