import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import MarkdownRenderizer from '../markdown-renderizer'
import styles from './index.module.scss'

export default function ClientDisplay ({
  client
}) {
  return (
    <>
      <div className='dis-list-group'>
        <div className={cn('dis-list-item dis-list-item-gray', styles.detailsItem)}>
          <div className='dis-list-group-header'>
            <h5>Account Information</h5>
          </div>
        </div>

        <div className={cn('dis-list-item', styles.detailsItem)}>
          <i className={cn('material-icons', styles.detailsIcon)}>info</i>
          <div className={styles.detailType}>Account ID</div>
          <div className={styles.detailsInfoRight}>
            {client?.customId}
          </div>
        </div>

        <div className={cn('dis-list-item', styles.detailsItem)}>
          <i className={cn('material-icons', styles.detailsIcon)}>person</i>
          <div className={styles.detailType}>Contact Name</div>
          <div className={styles.detailsInfoRight}>
            {client?.firstName || client?.lastName ? `${client?.firstName || ''} ${client?.lastName || ''}`.trim() : 'None'}
          </div>
        </div>

        <div className={cn('dis-list-item', styles.detailsItem)}>
          <i className={cn('material-icons', styles.detailsIcon)}>mail</i>
          <div className={styles.detailType}>Contact Email</div>
          <div className={styles.detailsInfoRight}>
            {client?.email ? <a href={`mailto:${client?.email}`}>{client?.email}</a> : 'None'}
          </div>
        </div>

        <div className={cn('dis-list-item', styles.detailsItem)}>
          <i className={cn('material-icons', styles.detailsIcon)}>call</i>
          <div className={styles.detailType}>Contact Phone</div>
          <div className={styles.detailsInfoRight}>
            {client?.prettyPrimaryPhone ? client?.prettyPrimaryPhone : 'None'}
            {client?.primaryPhoneExtension && ` ext. ${client?.primaryPhoneExtension}`}
          </div>
        </div>
      </div>
      <div className='dis-panel'>
        <div className='dis-list-item'>
          <div className={styles.detailsItem}>
            <i className={cn('material-icons', styles.detailsIcon)}>speaker_notes</i>
            <div className={styles.detailType}>Account Notes</div>
          </div>
          <div className={cn(styles.detailsInfo, styles.accountNotes)}>
            {client?.notes
              ? (
                <MarkdownRenderizer markdownText={client?.notes} />
                )
              : 'None'
            }
          </div>
        </div>
      </div>
    </>
  )
}

ClientDisplay.propTypes = {
  client: PropTypes.object.isRequired
}
