import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { generatePath, Link, useParams, useHistory } from 'react-router-dom'
import * as InlineSpinner from 'react-spinkit'
import { useQueryClient } from 'react-query'
import { ROUTES } from '../../routes'
import dayjs from '../../utilities/dayjs'
import { commaDeliminate } from '../../utilities/stringUtilities'
import { Spinner } from '../shared/Spinner'
import invoiceQuery from '../../graphql/queries/invoice'
import voidInvoiceMutation from '../../graphql/mutations/voidInvoice'
import exportRefundToQuickbooksMutation from '../../graphql/mutations/exportRefundToQuickbooks'
import exportPaymentToQuickbooksMutation from '../../graphql/mutations/exportPaymentToQuickbooks'
import createCustomerInvoice from '../../graphql/mutations/createCustomerInvoice'
import wastepayTransactionsQuery from '../../graphql/queries/wastepayTransactions'
import dispatcherTransactionsQuery from '../../graphql/queries/dispatcherTransactions'
import invoiceSettingsQuery from '../../graphql/queries/invoiceSettings'
import QUERY_KEYS from '../../graphql/queryKeys'
import exportInvoiceToQbo from '../../graphql/mutations/exportInvoiceToQbo'
import ButtonLinkOrNotification from '../../components/button-link-or-notification'
import QboExportInvoiceSuccess from '../../components/qbo-wastepay-export/QBOExportInvoiceSuccess'
import PageTitle from '../../components/page-title'
import InvoiceLineItemsTable from '../../components/invoice/invoice-line-items-table'
import Modal from '../../components/modal/Modal'
import InvoiceStatusLabel from '../../components/invoice/InvoiceStatusLabel'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import InvoiceTransactionsTable from '../../components/invoice/invoice-transactions-table'
import useQuery, { generateQueryKey } from '../../hooks/useQuery'
import useMutation from '../../hooks/useMutation'
import { captureError, captureErrorAndNotify, handleQBOInvoiceExportError } from '../../utilities/errorHandlers'
import notify from '../../utilities/notify'
import { formatPaymentsForQBOExportConfirmation } from '../../utilities/invoiceUtilities'
import exportAllPaymentsToQuickbooks from '../../graphql/mutations/exportAllPaymentsToQuickbooks'

export default function InvoicePage () {
  const [showVoidModal, setShowVoidModal] = useState(false)
  const [qboConfirmation, setQboConfirmation] = useState(false)
  const { id } = useParams()
  const history = useHistory()
  const { hauler, user } = useSelector(({ user }) => ({ hauler: user.hauler, user: user.user }))
  const queryClient = useQueryClient()

  const { mutate: exportToQBO, isLoading: exportToQBOLoading } = useMutation(exportInvoiceToQbo, {
    onSuccess ({ invoice }) {
      setQboConfirmation(true)
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.invoice, id], user.id), (oldData) => ({
        ...(oldData ?? {}),
        invoice: {
          ...(oldData?.invoice ?? {}),
          qboUrl: invoice?.qboUrl,
          qboInvoiceExportStatus: invoice?.qboInvoiceExportStatus,
          qboPaymentExportStatus: invoice?.qboPaymentExportStatus,
          qboPaymentUrl: invoice?.qboPaymentUrl
        }
      }))
    },
    onError: handleQBOInvoiceExportError
  })

  const { mutate: printCustomerInvoice, isLoading: printingCustomerInvoice } = useMutation(createCustomerInvoice, {
    onSuccess ({ link }) {
      return window.open(link, '_blank', 'noopener,noreferrer')
    },
    onError (error) {
      return captureErrorAndNotify(error, 'Error generating invoice PDF.', 6)
    }
  })

  const { data: { invoice } = {}, isFetching, refetch: refetchInvoice } =
    useQuery([QUERY_KEYS.invoice, id], invoiceQuery, {
      enabled: id !== undefined,
      onError: (error) => captureErrorAndNotify(error, 'Unable to fetch invoice')
    })

  const { data: invoiceSettingsData, isFetching: invoiceSettingsFetching } = useQuery([
    QUERY_KEYS.invoiceSettings,
    hauler.id,
    true
  ],
  invoiceSettingsQuery,
  {
    onError: (error) => captureErrorAndNotify(error, 'Error Fetching Invoice Settings')
  })

  const { data: transactions, isFetching: transactionsFetching, isError: wpTranscationError } = useQuery(
    [QUERY_KEYS.wastepayTransactions, { invoiceId: invoice?.id }],
    wastepayTransactionsQuery,
    {
      enabled: hauler.isWastepayConnected && Boolean(invoice?.dispatcherInvoiceNumber),
      onError: (error) => {
        captureError(error)
      }
    }
  )

  const { data: dispatcherTransactions, isError: dispatcherTranscationError } = useQuery(
    [QUERY_KEYS.dispatcherTransactions, { invoiceId: invoice?.uuid }],
    dispatcherTransactionsQuery,
    {
      enabled: Boolean(invoice?.uuid),
      onError: (error) => {
        captureError(error)
      }
    }
  )

  const { mutateAsync: exportRefundToQuickbooks } = useMutation(exportRefundToQuickbooksMutation, {
    onSuccess (data, variables) {
      updateTransactionCache(variables.invoiceId, data.refund)
      notify('success', `Transaction ${variables.transactionId} was successfully exported to QuickBooks`)
    },
    onError (error, variables) {
      captureErrorAndNotify(error, `Failed to export transaction ${variables.transactionId} to QuickBooks`)
    }
  })
  const { mutateAsync: exportPaymentToQuickbooks } = useMutation(exportPaymentToQuickbooksMutation, {
    onSuccess (data, variables) {
      updateTransactionCache(variables.invoiceId, data.payment)
    },
    onError (error, variables) {
      captureErrorAndNotify(error, `Failed to export transaction ${variables.transactionId} to QuickBooks`)
    }
  })
  const { mutateAsync: voidInvoice, isLoading: isVoidingInvoice } = useMutation(voidInvoiceMutation, {
    onSuccess () {
      handleCloseModal()
      notify('success', `Invoice ${invoice.dispatcherInvoiceNumber} was successfully voided`)
      history.push(ROUTES.invoices)
    },
    onError (error) {
      const failedToVoidText = `Failed to void invoice ${invoice?.dispatcherInvoiceNumber}`
      const [errorMessage] = error instanceof Error ? error.message.split(':') : []
      const err = errorMessage ? `${failedToVoidText}: ${errorMessage}` : failedToVoidText
      captureErrorAndNotify(error, err, 5)
    }
  })

  function handleOpenModal () {
    setShowVoidModal(true)
  }

  function handleCloseModal () {
    setShowVoidModal(false)
  }

  const hasInvoiceTickets = useMemo(() => {
    return invoice?.tickets?.length > 0
  }, [invoice])

  const invoiceStatus = useMemo(() => {
    const isOpen = invoice?.status === 'OPEN'
    if (!isOpen) {
      return invoice?.status
    }

    const currentDate = new Date()
    const dueDate = new Date(invoice.dueDate)
    if (invoiceSettingsData?.invoiceSettings?.sendInvoicesFrom === 'DISPATCHER' && dueDate < currentDate) {
      return 'OVERDUE'
    }

    return invoice?.status
  }, [invoice, invoiceSettingsData?.invoiceSettings.sendInvoicesFrom])

  const isOldReference = invoice?.qboDocNumberOld !== null || invoice?.qboInvoiceIdOld !== null
  const qboInvoiceNumber = isOldReference ? invoice?.qboDocNumberOld || invoice?.qboInvoiceIdOld : invoice?.qboDocNumber || invoice?.qboInvoiceId

  const paymentPath = generatePath(ROUTES.invoicePayment, { id })

  function handleQBOExport () {
    exportToQBO({ id })
  }

  function handlePreviewInvoiceClick () {
    printCustomerInvoice({ invoiceID: invoice.uuid })
  }

  function handleVoidInvoice () {
    voidInvoice({
      id
    })
  }

  function getRefundedAmount (total) {
    const firstTransaction = transactions?.wastepayTransactions?.[0]
    // if there hasn't been a payment, then nothing has been refunded
    const refundedAmount = firstTransaction?.remainingAmount || total

    return total - refundedAmount
  }

  function updateTransactionCache (invoiceId, transaction) {
    queryClient.setQueryData(generateQueryKey([QUERY_KEYS.wastepayTransactions, { invoiceId }], user.id), (oldData) => ({
      ...(oldData ?? {}),
      wastepayTransactions: {
        ...(oldData?.wastepayTransactions ?? {}),
        ...transaction
      }
    }))
  }

  async function handleTransactionQboExport (invoice, transaction) {
    switch (transaction.transactionType) {
      case 'SALE':
        if (transaction.provider === 'DISPATCHER') {
          return await exportPaymentToQuickbooks({ invoiceId: invoice.id, dispatcherPaymentId: transaction.id })
        }
        return await exportPaymentToQuickbooks({ invoiceId: invoice.id, transactionId: transaction.id })
      case 'RETURN':
        if (transaction.provider === 'DISPATCHER') {
          return await exportRefundToQuickbooks({ invoiceId: invoice.id, dispatcherRefundId: transaction.id })
        }
        return await exportRefundToQuickbooks({ invoiceId: invoice.id, transactionId: transaction.id })
      default: {
        const error = new Error('Unhandled transaction type to export to QuickBooks')
        captureErrorAndNotify(error, 'Unable to export transaction to QuickBooks')
      }
    }
  }

  function formateDueDate (dueDate, paymentTerms) {
    if (paymentTerms === 'DUE_UPON_RECEIPT') {
      return 'Due Upon Receipt'
    }

    return dayjs(dueDate).format('MMM D, YYYY')
  }

  function readableTerms (paymentTerms) {
    return paymentTerms.split('_').map(v => `${v.substring(0, 1)}${v.substring(1).toLowerCase()}`).join(' ')
  }

  const { mutate: retryPaymentsExport } = useMutation(exportAllPaymentsToQuickbooks, {
    onSuccess ({ invoice: newInvoiceData }) {
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.invoicePaymentDetails, id], user.id), (oldData) => ({
        ...oldData,
        invoice: { ...oldData.invoice, ...{ dispatcherPayments: newInvoiceData.dispatcherPayments }, ...{ wastepaySales: newInvoiceData.wastepaySales } }
      }))
      notify('success', 'Payments export successful')
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error exporting payments to Quickbooks')
    }
  })

  function handleRetryPaymentExport (data) {
    return retryPaymentsExport({ id })
  }

  function handleConfirmation () {
    setQboConfirmation(false)
    refetchInvoice()
  }

  return (
    <NormalLayoutContainer showBackLink>
      <Modal
        className={styles.voidInvoiceModalContainer}
        isOpen={showVoidModal}
        closeModal={handleCloseModal}>

        <div className={cn('dis-panel', styles.voidInvoiceContainer)}>
          <h3>Void Invoice {invoice?.dispatcherInvoiceNumber}</h3>
          <p className={cn(styles.voidInvoiceText)}>
            Are you sure you want to void this invoice? When voided, it cannot be undone.
          </p>
          <div className={cn(styles.voidInvoiceButtonWrapper)}>
            <button
              className='dis-btn dis-btn-lg dis-btn-blank'
              onClick={handleCloseModal}>
              No, Cancel
            </button>
            <button
              className='dis-btn dis-btn-lg dis-btn-danger'
              disabled={isVoidingInvoice}
              onClick={handleVoidInvoice}>
              Yes, Void Invoice {invoice?.dispatcherInvoiceNumber}
            </button>
          </div>
          <footer className={cn(styles.voidInvoicesFooter)}>Tickets associated with voided invoices are immediately available for future invoicing.</footer>
        </div>
      </Modal>

      {isFetching || transactionsFetching || exportToQBOLoading || invoiceSettingsFetching
        ? <Spinner isFetching />
        : qboConfirmation
          ? (<>
            <PageTitle>Export Invoice #{invoice?.dispatcherInvoiceNumber} To QuickBooks Online</PageTitle>
            <QboExportInvoiceSuccess
              invoice={invoice}
              secondaryButton={<ConfirmationButton onClick={handleConfirmation} />}
              transactions={formatPaymentsForQBOExportConfirmation(invoice)}
              retry={handleRetryPaymentExport}
            />
          </>)
          : (<>
            <div className={styles.header}>
              <PageTitle>Invoice #{invoice?.dispatcherInvoiceNumber || qboInvoiceNumber}</PageTitle>
              <div className={styles.invoiceButtons}>
                {invoice?.status === 'OPEN' && <div>
                  <button
                    type='button'
                    onClick={handleOpenModal}
                    className={cn(styles.editInvoiceBtn, 'dis-btn dis-btn-lg dis-btn-danger')}>
                    Void Invoice
                  </button>
                  <Link to={generatePath(ROUTES.invoiceEdit, { id: invoice.id })}
                    className={cn(styles.editInvoiceBtn, 'dis-btn dis-btn-lg dis-btn-primary-dk')}>
                    Edit Invoice
                  </Link>
                  {(hauler.isWastepayConnected || invoiceSettingsData?.invoiceSettings.sendInvoicesFrom === 'DISPATCHER') && (
                    <ButtonLinkOrNotification
                      linkClasses={cn('dis-btn dis-btn-lg dis-btn-primary', {
                        'dis-disabled-link': !hasInvoiceTickets
                      })}
                      linkHref={generatePath(ROUTES.invoicePayment, { id })}
                      linkText='COLLECT PAYMENT'
                      notificationText='Account has not been exported to WastePay.'
                      notificationType='error'
                      dontExport={hauler.isWastepayConnected && !invoice.client.wastepayCustomerId && invoice.client.didPromptClientExportSelection}
                    />
                  )}
                </div>}
                {((invoice?.status === 'PAID' || invoice?.status === 'CLOSED_IN_QBO')) && // TODO: also check to see if they use Dispatcher as their invoice provider
                  (
                    <button
                      type='button'
                      onClick={handlePreviewInvoiceClick}
                      className={cn(styles.boldBtn, 'dis-btn dis-btn-lg dis-btn-gray')}>
                      {printingCustomerInvoice ? (<InlineSpinner name='circle' fadeIn='none' />) : 'View/Print Invoice'}
                    </button>
                  )
                }
                {((hauler.quickbooks.isConnected && !invoice?.qboInvoiced && !hauler.isWastepayConnected) ||
                      (hauler.quickbooks.isConnected && !invoice?.qboInvoiced && hauler.isWastepayConnected && invoice.status === 'PAID') ||
                      (invoice?.status === 'OPEN' && hauler.quickbooks.isConnected && !hauler.isWastepayConnected)) && (
                        <button
                          type='button'
                          className={cn('dis-btn dis-btn-lg dis-btn-primary', {
                            'dis-disabled-link': !hasInvoiceTickets
                          })}
                          onClick={handleQBOExport}>
                          Export To QBO
                        </button>
                )}
                {invoice?.qboInvoiced && (
                  <div className={styles.headerBtns}>
                    {isOldReference && <span className={styles.oldLink}>Linked to prior QBO account</span>}

                    {!isOldReference && invoice?.qboUrl && (
                      <a className={cn(styles.qboBtn, 'dis-btn dis-btn-lg dis-btn-gray')}
                        href={invoice?.qboUrl}
                        target='_blank noopener noreferrer'>
                        View In QBO
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className={styles.details}>
              <div className={styles.detailsRow}>
                <div className='dis-panel'>
                  <div className='dis-panel-header'>
                    <h5>Invoice Details</h5>
                  </div>

                  <div className='dis-panel-body'>

                    <div className={styles.detailTitle}>Account Name</div>
                    <div className={cn(styles.detail, styles.detailMB)}>
                      <Link to={generatePath(ROUTES.client, { clientId: invoice?.client?.id })}>
                        {invoice?.client.name}
                      </Link>
                    </div>

                    <div className={styles.detailTitle}>Status</div>
                    <div className={cn(styles.detail, styles.detailMB)}>
                      <InvoiceStatusLabel>{invoiceStatus}</InvoiceStatusLabel>
                    </div>

                    {
                      invoice?.status !== 'VOIDED' && (
                        <>
                          <div className={styles.detailTitle}>Total</div>
                          <div className={cn(styles.detail, styles.detailMB)}>${commaDeliminate(invoice?.total)}</div>

                          {(hauler.isWastepayConnected || invoiceSettingsData?.invoiceSettings.sendInvoicesFrom === 'DISPATCHER') && (<>
                            <div className={styles.detailTitle}>Outstanding</div>
                            <div className={cn(styles.detail, styles.detailMB)}>
                              ${invoice?.status === 'VOIDED' ? 0 : commaDeliminate(invoice.outstandingBalance || 0)}
                            </div>
                          </>)}
                          {(hauler.isWastepayConnected && getRefundedAmount(invoice?.total) > 0) && (<>
                            <div className={styles.detailTitle}>Refund</div>
                            <div className={cn(styles.detail, styles.detailMB)}>
                              ${commaDeliminate(getRefundedAmount(invoice?.total))}
                            </div>
                          </>)}
                        </>
                      )
                    }

                    <div className={styles.detailTitle}>Created Date</div>
                    <div className={cn(styles.detail, styles.detailMB)}>
                      {dayjs(invoice?.createdAt).format('MMM D, YYYY')} by
                      <Link to={generatePath(ROUTES.teamMember, { id: invoice?.creator?.id })}>
                        {` ${invoice?.creator?.firstName} ${invoice?.creator?.lastName}`}
                      </Link>
                    </div>

                    {
                      invoice?.status === 'VOIDED' && (
                        <>
                          <div className={styles.detailTitle}>Voided On</div>
                          <div className={cn(styles.detail, styles.detailMB)}>
                            {formateDueDate(dayjs(invoice.voidedAt).format('MMM D, YYYY'), invoice.paymentTerms)}
                          </div>
                        </>
                      )
                    }

                    {
                      invoice?.status !== 'VOIDED' && (
                        <>
                          {
                          (invoiceSettingsData?.invoiceSettings.sendInvoicesFrom === 'DISPATCHER' && invoice != null && invoice.paymentTerms) && (
                            <>
                              <div className={styles.detailTitle}>Due Date</div>
                              <div className={cn(styles.detail, styles.detailMB)}>
                                {formateDueDate(dayjs(invoice.dueDate).format('MMM D, YYYY'), invoice.paymentTerms)}
                              </div>
                              {
                                invoice.paymentTerms !== 'DUE_UPON_RECEIPT' && (
                                  <>
                                    <div className={styles.detailTitle}>Terms</div>
                                    <div className={cn(styles.detail, styles.detailMB)}>
                                      {readableTerms(invoice.paymentTerms)}
                                    </div>
                                  </>
                                )
                              }
                              <div className={styles.detailTitle}>Last Sent On</div>
                              <div className={cn(styles.detail, styles.detailMB)}>
                                {invoice.lastSent ? dayjs(invoice.lastSent).format('MMM D, YYYY h:mm A') : '--'}
                              </div>
                              <div className={styles.detailTitle}>Last Sent To</div>
                              <div className={cn(styles.detail, styles.detailMB)}>
                                {invoice.billTo ?? '--'}
                              </div>
                            </>
                          )
                        }

                          {hauler.quickbooks.isConnected && (<>
                            <div className={styles.detailTitle}>QuickBooks Sync Status</div>
                            <div className={styles.detail}>{invoice?.qboInvoiced ? 'Success' : 'Not Synced'}</div>
                          </>)}
                        </>
                      )
                    }

                  </div>
                </div>

                <div className='dis-panel'>
                  <div className='dis-panel-header'>
                    <div>
                      <h5>Line Items</h5>
                    </div>
                  </div>
                  <div className='dis-panel-body'>
                    <InvoiceLineItemsTable
                      hasInvoiceTickets={hasInvoiceTickets}
                      invoice={invoice}
                      qboInvoiced={invoice?.status === 'CLOSED_IN_QBO'}
                    />
                  </div>
                </div>
              </div>
              {
                invoice.status !== 'VOIDED' && (
                  <div className='dis-panel'>
                    <div className='dis-panel-header'>
                      <div>
                        <h5>Invoice Transactions</h5>
                      </div>
                    </div>
                    <div className='dis-panel-body'>
                      <InvoiceTransactionsTable
                        hasInvoiceTickets={hasInvoiceTickets}
                        invoice={invoice}
                        paymentPath={paymentPath}
                        transactions={transactions?.wastepayTransactions ?? []}
                        dispatcherTransactions={dispatcherTransactions?.dispatcherTransactions ?? []}
                        onQboExport={handleTransactionQboExport}
                        isWPError={wpTranscationError}
                        isDispError={dispatcherTranscationError}
                        isQBOSynced={hauler.quickbooks.isConnected}
                        isWastepayConnected={hauler.isWastepayConnected}
                        invoicer={invoiceSettingsData?.invoiceSettings.sendInvoicesFrom}
                      />
                    </div>
                  </div>
                )
              }
            </div>
          </>)
      }
    </NormalLayoutContainer>
  )
}

function ConfirmationButton ({ onClick }) {
  return (
    <button
      type='button'
      className={cn('dis-btn dis-btn-lg dis-btn-gray', styles.qboConfirmationBtn)}
      onClick={onClick}>
      Back To Invoice
    </button>
  )
}

ConfirmationButton.propTypes = {
  onClick: PropTypes.func.isRequired
}
