import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { generatePath, Link } from 'react-router-dom'
import styles from '../../src/components/ticket-display/ticket-sites/ticket-sites.module.scss'
import ticketQuery from '../graphql/queries/ticket'
import sitesQuery from '../graphql/queries/sites'
import QUERY_KEYS from '../graphql/queryKeys'
import useQuery from '../hooks/useQuery'
import { Spinner } from '../pages/shared/Spinner'
import { required } from '../utilities/commonValidators'
import { commaDeliminate } from '../utilities/stringUtilities'
import { captureErrorAndNotify } from '../utilities/errorHandlers'
import { ROUTES } from '../routes'

export default function SiteEditModal ({ onCancel, onSave, siteEditModal, onRemove, haulerID }) {
  function handleSubmit (values) {
    onSave({ ...siteEditModal, ...values })
  }

  const { data: { sites }, isFetching: isFetchingSites } = useQuery([
    QUERY_KEYS.sites,
    haulerID
  ], sitesQuery, {
    placeholderData: { sites: [] },
    onError (error) {
      captureErrorAndNotify(error, 'Error fetching sites')
    }
  })

  const { data: { ticket }, isFetching: isFetchingTicket } = useQuery(
    [QUERY_KEYS.ticket, siteEditModal.ticketId],
    ticketQuery, {
      onError (error) {
        captureErrorAndNotify(error, 'Error loading ticket')
      },
      placeholderData: { ticket: {} }
    })

  const nonMatchedSites = useMemo(
    () => sites.filter(singleSite => !ticket?.sites?.some(ticketSite => singleSite.id === ticketSite.id)),
    [ticket, sites]
  )

  if (isFetchingSites) {
    return (<Spinner isFetching />)
  }

  return (
    <div className='bg-white shadow w-4/6 relative md:-top-1/4'>
      <h2 className='p-6'>{'Associated Sites'}</h2>
      <div className='p-6'>
        <Form onSubmit={handleSubmit} validate={validate}>
          {({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit}>
              <div className='mb-8'>
                <Field name='siteId'>
                  {({ input, meta }) => (<>
                    <label className={classNames('w-full', { 'text-red': meta.invalid && meta.touched })} htmlFor='siteId'>Select Site</label>
                    <select {...input} id='siteId' className='form-control'>
                      <option value={null}></option>
                      {nonMatchedSites.map(tm => {
                        let optionValue = `${tm.name}`
                        if (tm.cost) optionValue += ` - $${commaDeliminate(tm.cost)}`
                        return (
                          <option key={tm.id} value={tm.id}>{`${optionValue}`}</option>
                        )
                      })}
                    </select>
                    <div className={classNames({ 'border-b border-red border-1 relative bottom-1': meta.invalid && meta.touched })}></div>
                    {meta.invalid && meta.touched && <h5>Required</h5>}
                  </>)}
                </Field>
              </div>
              <div className='py-2'>
                {isFetchingTicket && <Spinner isFetching />}
                {!isFetchingTicket && ticket.sites.map((ticketSite, index) => {
                  return (
                    <li key={ticketSite.id} className={styles.siteItemContainer}>
                      <div className={classNames(styles.siteItem, { [`${styles.lastSiteItem}`]: index === ticket.sites.length - 1 })}>
                        <Link to={generatePath(ROUTES.site, { id: ticketSite.id })}>{ticketSite.name}</Link>
                        <span className={styles.siteItemName}>
                          - {ticketSite.siteType.name}
                          {ticketSite.cost ? (<> - ${commaDeliminate(ticketSite.cost)}</>) : null}
                        </span>
                      </div>
                      <button className={styles.removeBtn} type='button' onClick={() => { onRemove({ ...siteEditModal, siteId: ticketSite.id }) }}>
                        <i className={classNames('material-icons', styles.removeIcon)}>close</i>
                      </button>
                    </li>
                  )
                })}
              </div>
              <div className='flex justify-end'>
                <button type='button'
                  className='dig-btn dis-btn-lg dis-btn-white mr-1'
                  onClick={onCancel}>
                  Close
                </button>
                <button type='submit'
                  className='dis-btn dis-btn-lg dis-btn-primary'
                  disabled={invalid}>
                  Add
                  <i className='material-icons dis-btn-icon'>check</i>
                </button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  )
}

SiteEditModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  haulerID: PropTypes.string.isRequired,
  siteEditModal: PropTypes.shape({
    ticketId: PropTypes.string.isRequired
  })
}

function validate (values) {
  return {
    siteId: required(values.siteId)
  }
}
