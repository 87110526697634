import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { generatePath, Link } from 'react-router-dom'
import { ROUTES } from '../../../../routes'
import styles from './index.module.scss'

export default function OpenStatus ({ invoice, hauler }) {
  return (
    <ul className={cn('dropdown-menu', styles.invoiceActions)}>
      <li>
        <Link to={generatePath(ROUTES.invoicePayment, { id: invoice.id })}>
          <span>Collect Payment</span>
          <i className='material-icons'>paid</i>
        </Link>
        <Link to={generatePath(ROUTES.invoice, { id: invoice.id })}>
          <span>Void Invoice</span>
          <i className='material-icons'>close</i>
        </Link>
        {hauler.quickbooks.isConnected && <Link to={generatePath(ROUTES.invoicesExport, { id: invoice?.id })}>
          <span>Export to QBO</span>
          <i className='material-icons'>forward</i>
        </Link>}
      </li>
    </ul>
  )
}
OpenStatus.propTypes = {
  hauler: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isWastepayApproved: PropTypes.bool.isRequired,
    quickbooks: PropTypes.shape({
      isConnected: PropTypes.bool.isRequired
    }).isRequired
  }),
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
}
