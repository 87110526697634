import { loader } from 'graphql.macro'

export default async function exportInvoices ({ dataLoaders, variables }) {
  const schema = loader('./exportInvoices.graphql')
  const {
    haulerId,
    sort,
    invoiceNumber = null,
    status = null,
    createdDateRange = null,
    accountName = null,
    clientId = null
  } = variables
  const sortColumnEnumMap = {
    invoiceNumber: 'DISPATCHER_INVOICE_NUMBER',
    qboDocNumber: 'QBO_DOC_NUMBER',
    accountName: 'ACCOUNT_NAME',
    createdAt: 'CREATED_AT',
    qboUploadStatus: 'QBO_UPLOAD_STATUS',
    ticketsCount: 'TICKETS_COUNT',
    billingStatus: 'BILLING_STATUS',
    status: 'STATUS'
  }

  const sortColumnEnum = sortColumnEnumMap[sort.column]
  if (!sortColumnEnum) {
    return Promise.reject(new Error(`Invalid sort.column: ${sort.column}`))
  }

  const input = {
    haulerId,
    sortColumn: sortColumnEnum,
    sortDirection: sort.direction.toUpperCase(),
    createdAtRange: createdDateRange,
    invoiceNumber,
    accountName,
    clientId,
    status
  }

  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input
  })
  return response.exportInvoicesSearch
}
