import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import dayjs from '../../../utilities/dayjs'
import styles from './ticket-job-details.module.scss'
import { Link, generatePath } from 'react-router-dom'
import { ROUTES } from '../../../routes'
import { prettyAddressWithDetails, googleMapsLink } from '../../../utilities/locationUtilities'
import MarkdownRenderizer from '../../markdown-renderizer'

export default function TicketJobDetails ({ job }) {
  return (
    <div className='dis-list-group'>

      <div className='dis-list-item dis-list-item-gray'>
        <div className='dis-list-group-header'>
          <h5>
            Job Details
          </h5>
          <Link className='dis-list-group-header-link' to={generatePath(ROUTES.job, { id: job.id })}>
            Go To Job
          </Link>
        </div>
      </div>

      <div className='dis-list-item'>
        <div className='dis-list-item-header'>Address</div>
        <div className='dis-list-item-text'>
          <a href={googleMapsLink(job)} target='_blank' rel='noopener noreferrer'>
            {prettyAddressWithDetails(job)}
          </a>
        </div>
      </div>

      <div className='dis-list-item'>
        <div className='dis-list-item-header'>Primary Contact </div>
        {!job.firstName && !job.lastName
          ? <div>
            No Name
          </div>
          : <div className='dis-list-item-text'>{job.firstName || ''} {job.lastName || ''}</div>}
        {job.email && <div className='dis-list-item-text'>{job.email}</div>}
        {job.prettyPrimaryPhone && <div className='dis-list-item-text'>{job.prettyPrimaryPhone }</div>}
      </div>

      <div className='dis-list-item'>
        <div className='dis-list-item-header'>Secondary Contact</div>
        {!job.secondaryFirstName && !job.secondaryLastName
          ? <div>No Name</div>
          : <div className='dis-list-item-text'>{job.secondaryFirstName || ''} {job.secondaryLastName || ''}</div>}
        {job.secondaryEmail && <div className='dis-list-item-text'>{job.secondaryEmail}</div>}
        {job.prettySecondaryPhone && <div className='dis-list-item-text'>{job.prettySecondaryPhone}</div>}
      </div>

      <div className='dis-list-item'>
        <div className='dis-list-item-header'>Job Notes</div>
        <div className={job.notes ? 'dis-list-item-text' : ''}>
          {job?.notes
            ? (
              <MarkdownRenderizer markdownText={job?.notes} />
              )
            : 'No Job Notes'
          }
        </div>
      </div>

      <div className='dis-list-item'>
        <div className='dis-list-item-header'>{'Admin Notes (Visible Only By Admins)'}</div>
        <div className={job.internalAdminNotes ? 'dis-list-item-text' : ''}>
          {job?.internalAdminNotes
            ? (
              <MarkdownRenderizer markdownText={job?.internalAdminNotes} />
              )
            : 'No Admin Notes'
          }
        </div>
      </div>

      <div className='dis-list-item'>
        <div className='dis-list-item-header'>Assets on Job Site</div>
        {job.assets.length === 0 && <div className={job.assets.length ? 'dis-list-item-text' : ''}>No Assets on Job</div>}
        <div className={styles.jobDetailsResourceList}>
          {job.assets.map(asset => (
            <li key={asset.id} className={classNames('dis-list-item', styles.jobDetailsResource)}>
              <Link className={styles.jobDetailsLink} to={generatePath(ROUTES.resource, { id: asset.id })}>
                {asset.uid} ({asset.shortCode || 'UNKNOWN'})
              </Link>
              <div className={styles.jobDetailsTime}>
                Marked:&nbsp;
                <LastMoved lastMoved={asset.lastMoved} />
                &nbsp;
                &nbsp;
              </div>
            </li>
          ))}
        </div>
      </div>
    </div>
  )
}

TicketJobDetails.propTypes = {
  job: PropTypes.object.isRequired
}

function LastMoved ({ lastMoved }) {
  if (!lastMoved) return 'N/A'

  const parsedDay = dayjs(lastMoved)
  const formattedDate = parsedDay.format('MMM D, YYYY, h:mm a')
  const formattedDayCount = parsedDay.fromNow()
  return (
    <span>
      <strong>
        {formattedDayCount}
      </strong>
      &nbsp;at {formattedDate}
    </span>
  )
}
LastMoved.propTypes = {
  lastMoved: PropTypes.string
}
